import React from 'react'
import NavbarPure from '../components/theme/Header/NavBarPure'
import { Layout, SEO } from 'Common'

export default () => (
  <Layout>
    <NavbarPure />
    <SEO title="Make ¡t Fresh!" location="/recipe-search" />
    <center>
      <h1>In Construction</h1>
      <p>Just give it a bit!</p>
    </center>
  </Layout>
)
